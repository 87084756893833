<template>
  <div class="content-body">
    <div class="row mt-2 mg-sm-0 no-print">
      <div class="col-6">
        <div class="content-header pd-l-0">
          <div>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="#">Account Report</a></li>
                <li class="breadcrumb-item text-capitalize" aria-current="page">
                  VAT Report
                </li>
                <li
                  class="breadcrumb-item text-capitalize active"
                  aria-current="page"
                >
                  Kharid Khata
                </li>
              </ol>
            </nav>
            <h4 class="content-title content-title-xs">
              VAT Reprot (Kharid Khata)
            </h4>
          </div>
        </div>
        <!-- content-header -->
      </div>
      <!-- col -->
      <div class="col-6 d-flex justify-content-end pr-2 pb-2">
        <div class="bg-theam-secondary d-flex m-2 p-1">
          <div class="report-tool-icon mr-3 ml-1">
            <i class="fas fa-print" title="Print" @click="print"></i>
          </div>
          <!-- <div class="report-tool-icon mr-1">
            <i class="fas fa-download" title="Download"></i>
          </div> -->
        </div>
      </div>
      <!-- col -->
    </div>
    <!-- row -->
    <div class="bg-theam-secondary table-search-head pt-0">
      <div class="row">
        <div class="filter-input col-md-12 d-block mr-0 pr-0">
          <select
            v-model="filterParams.pageIndex"
            @change="selectTotalData"
            class="mg-t-5 mr-2"
          >
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="500">500</option>
            <option value>All</option>
          </select>
          <select
            class="mr-2 mg-t-5"
            v-model="filterParams.fiscal_year"
            @change="getFilteredDataWithoutDates"
          >
            <option
              v-for="(year, index) in sessionYears"
              :key="index"
              :value="year"
            >
              {{ year }}
            </option>
          </select>
          <select
            v-model="filterParams.branch"
            class="filter-input mr-2 mg-t-5"
            @change="selectBranch"
          >
            <option value>All Branch</option>
            <option
              v-for="(branch, index) in branches"
              :key="index"
              :value="branch.id"
            >
              {{ branch.name }}
            </option>
          </select>
          <SearchDayMonthComponent
            @clicked="monthDayClicked"
          ></SearchDayMonthComponent>

          <div class="d-inline-block mr-2">
            <label class="filter-lavel mb-0 mr-1 mg-t-5">From</label>
            <input
              type="date"
              v-model="filterParams.from_date"
              @change="selectFromDate"
              placeholder="From"
              class="filter-input mg-t-5 mr-2"
            />
            <label class="filter-lavel mb-0 mg-t-5 mr-2">To</label>
            <input
              type="date"
              v-model="filterParams.to_date"
              @change="selectEndDate"
              placeholder="To"
              class="mg-t-5 filter-input"
            />
          </div>
          <input
            type="text"
            v-model="filterParams.searched"
            @keyup="searchedKeyword"
            class="filter-input mg-t-5 mr-3"
            placeholder="Search..."
          />
          <div class="float-right mr-3 mg-t-5">
            <select
              class="filter-input ml-2 float-right"
              v-model="route"
              @change="filterReportType"
            >
              <option value="/account-report/vat-report/bikrikhata">
                Bikri Khata
              </option>
              <option value="/account-report/vat-report/kharidkhata">
                Kharid Khata
              </option>
              <!-- <option value="/account-report/vat-report">Month Wise</option>
              <option value="/account-report/vat-report/day-wise">Day Wise</option>
              <option value="/account-report/vat-report/branch-wise">Branch Wise</option> -->
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="row py-0">
      <div class="col-md-12 overflow-x-auto">
        <table class="table table2 table-hover" id="tableData">
          <thead>
            <tr>
              <th class="wd-5p table-start-item">S.N.</th>
              <th class="wd-10p">Date</th>
              <th class="wd-15p">Source</th>
              <td class="wd-10p">Chalan No</td>
              <th class="wd-15p">Supplier</th>
              <th class="wd-5p">Pan No</th>
              <td class="wd-10p">Prch. Amt.</td>
              <td class="wd-10p">Non-VAT able</td>
              <th class="wd-10p">VAT able Amt.</th>
              <th class="wd-10p">VAT Amount</th>
            </tr>
          </thead>
          <tbody v-if="!loading && kharidKhataData.length > 0">
            <tr v-for="(kharidKhata, index) in kharidKhataData" :key="index">
              <td scope="row" class="table-start-account">
                {{ pageSerialNo + index }}
              </td>
              <td>{{ kharidKhata.date }}</td>
              <td>
                <span v-if="kharidKhata.type == 1">
                  <span v-if="kharidKhata.shipping_type == 1">
                    {{
                      "Imported Purchase - " +
                      kharidKhata.prefix +
                      kharidKhata.receipt_no
                    }}
                  </span>
                  <span v-else>
                    {{
                      "Local Purchase - " +
                      kharidKhata.prefix +
                      kharidKhata.receipt_no
                    }}
                  </span>
                </span>
                <span v-else-if="kharidKhata.type == 2">
                  <span v-if="kharidKhata.shipping_type == 1">
                    {{
                      "Imported Assets - " +
                      kharidKhata.prefix +
                      kharidKhata.receipt_no
                    }}
                  </span>
                  <span v-else>
                    {{
                      "Local Assets - " +
                      kharidKhata.prefix +
                      kharidKhata.receipt_no
                    }}
                  </span>
                </span>
                <span v-else>
                  <span v-if="kharidKhata.shipping_type == 1">
                    {{
                      "Imported Expense - " +
                      kharidKhata.prefix +
                      kharidKhata.receipt_no
                    }}
                  </span>
                  <span v-else>
                    {{
                      "Local Expense - " +
                      kharidKhata.prefix +
                      kharidKhata.receipt_no
                    }}
                  </span>
                </span>
              </td>
              <td>{{ kharidKhata.chalan_no }}</td>
              <td>{{ kharidKhata.name }}</td>
              <td>{{ kharidKhata.pan_vat_no }}</td>
              <td>{{ parseDigitForSlip(kharidKhata.purchase_amount) }}</td>
              <td>{{ parseDigitForSlip(kharidKhata.non_vatable_amount) }}</td>
              <td>{{ parseDigitForSlip(kharidKhata.vatable_amount) }}</td>
              <td>{{ parseDigitForSlip(kharidKhata.vat_amount) }}</td>
            </tr>
          </tbody>
          <tbody v-else-if="error">
            <tr>
              <td colspan="11" style="text-align: center">
                {{ errorMessage }}
              </td>
            </tr>
          </tbody>
          <tbody v-else-if="!loading && kharidKhataData.length == 0">
            <tr>
              <td colspan="11" style="text-align: center">No records found.</td>
            </tr>
          </tbody>
          <tbody v-else-if="loading">
            <tr>
              <td colspan="11" style="text-align: center">Loading.....</td>
            </tr>
          </tbody>
        </table>
        <Paginate
          v-model="page"
          :pageCount="totalPageCount"
          :containerClass="'pagination'"
          :clickHandler="clickCallback"
          v-if="totalPageCount > 1"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Services from "./Services/Service";
import SearchDayMonthComponent from "../../../../shared/SearchDayMonthComponent";
import Paginate from "vuejs-paginate";
import _ from "lodash";
export default {
  components: {
    Paginate,
    SearchDayMonthComponent,
  },
  data() {
    return {
      sessionYears: ["2021-22"],
      route: this.$route.path,
      loading: true,
      error: false,
      totalPageCount: 0,
      pageReload: "",
      pageSerialNo: 1,
      errorMessage: "",
      page: 1,
      branches: [],
      filterParams: {
        pageIndex: 10,
        branch: "",
        sales_type: "",
        day: new Date().getDate(),
        month: new Date().getMonth(),
        year: new Date().getFullYear(),
        from_date: "",
        to_date: "",
        searched: "",
        offset: 0,
        fiscal_year: "2021-22",
      },
    };
  },
  computed: {
    ...mapGetters("vatReport", ["kharidKhataData"]),
  },
  beforeMount() {
    // get Data
    this.getFilteredData();
    this.pageReload = true;
    Services.getUserAccessBranches()
      .then((response) => {
        this.branches = response.data.data;
        // this.sessionYears = response.data.data.fiscalYears;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  methods: {
    //print page
    print() {
      window.print();
    },
    //switch pages
    filterReportType() {
      if (this.$route.path != this.route) {
        this.$router.push(this.route);
      }
    },
    //month day component clicked
    monthDayClicked(value) {
      if (value) {
        this.filterParams.from_date = "";
        this.filterParams.to_date = "";
        this.filterParams.day = value.day;
        this.filterParams.month = value.month;
        this.filterParams.year = value.year;
      }
      //make api call
      this.page = 1;
      this.pageSerialNo = 1;
      this.filterParams.offset = 0;
      this.getFilteredData();
    },
    // pagination clicked
    clickCallback: function (page) {
      if (page == 1) {
        this.pageSerialNo = 1;
      } else {
        this.pageSerialNo = (page - 1) * this.filterParams.pageIndex + 1;
      }
      this.filterParams.offset = (page - 1) * this.filterParams.pageIndex;
      this.getFilteredDataWithoutDates();
    },
    onChangePage(pageNo) {
      console.log(pageNo);
    },
    selectTotalData() {
      this.page = 1;
      this.pageSerialNo = 1;
      this.filterParams.offset = 0;
      this.getFilteredDataWithoutDates();
    },
    selectBranch() {
      this.page = 1;
      this.pageSerialNo = 1;
      this.filterParams.offset = 0;
      this.getFilteredDataWithoutDates();
    },
    selectSalesType() {
      this.page = 1;
      this.pageSerialNo = 1;
      this.filterParams.offset = 0;
      this.getFilteredDataWithoutDates();
    },
    selectFromDate() {
      this.page = 1;
      this.pageSerialNo = 1;
      this.filterParams.offset = 0;
      this.getFilteredDataWithoutDates();
    },
    selectEndDate() {
      if (this.filterParams.from_date) {
        this.page = 1;
        this.pageSerialNo = 1;
        this.filterParams.offset = 0;
        this.getFilteredDataWithoutDates();
      }
    },

    //################ Searched Keyword ##################
    searchedKeyword: _.debounce(function () {
      this.page = 1;
      this.pageSerialNo = 1;
      this.filterParams.offset = 0;
      this.getFilteredDataWithoutDates();
    }, 500),

    // today/month section
    getFilteredData() {
      this.loading = true;
      let params = {
        pageIndex: this.filterParams.pageIndex,
        branch: this.filterParams.branch,
        sales_type: this.filterParams.sales_type,
        today: this.filterParams.day,
        month: this.filterParams.month + 1,
        year: this.filterParams.year,
        from_date: this.filterParams.from_date,
        to_date: this.filterParams.to_date,
        searched: this.filterParams.searched,
        page_reload: this.pageReload,
        offset: this.filterParams.offset,
      };
      this.ajaxRequest(params);
    },
    // without today/month section
    getFilteredDataWithoutDates() {
      this.loading = true;
      let params = {
        pageIndex: this.filterParams.pageIndex,
        branch: this.filterParams.branch,
        sales_type: this.filterParams.sales_type,
        today: "",
        month: "",
        year: this.filterParams.year,
        from_date: this.filterParams.from_date,
        to_date: this.filterParams.to_date,
        searched: this.filterParams.searched,
        page_reload: this.pageReload,
        offset: this.filterParams.offset,
      };
      this.ajaxRequest(params);
    },

    ajaxRequest(params) {
      this.error = false;
      Services.getKharidKhataReportFilterDatas(params)
        .then((response) => {
          this.$store.commit(
            "vatReport/setKharidKhataData",
            response.data.data.kharid
          );
          this.totalPageCount = response.data.data.total_no_of_pages;
        })
        .catch((error) => {
          this.error = true;
          if (
            !Object.prototype.hasOwnProperty.call(
              error,
              "response.data.error_message"
            )
          ) {
            if (error.response.data.error_message == "Access Denied.") {
              this.errorMessage = "Access Denied !!!";
            } else {
              this.errorMessage = "Error fetching data from the server.";
            }
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style scoped>
</style>
